import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'

import '../styles/media.page.css'
import {rhythm} from "../utils/typography";
import download from '../data/downloads'
import video from '../data/video'
import ReactHtmlParser from "react-html-parser";

class Media extends React.Component {
    render() {
        const siteTitle = get(this,'props.data.site.siteMetadata.title');
        const siteDescription = get(this,'props.data.site.siteMetadata.description');

        return (
            <Layout style={{ maxWidth: rhythm(45) }}>
                <Helmet
                    htmlAttributes={{ lang: 'fr' }}
                    meta={[{ name: 'description', content: siteDescription }]}
                    title={`${siteTitle} | Média`}
                />

                <h2>Téléchargements</h2>
                <br/>
                {download.map(d => (
                    <div style={{padding: '0 10px'}} key={d.headline}>
                        {d.icon}&nbsp;
                        <a style={{color: '#cc6434', fontWeight: 'bold'}} href={d.doc} target="_blank">
                            {d.headline}
                        </a>&nbsp;<em>({d.date} - {d.size})</em>

                        <br/>
                        <p style={{padding: '0 30px', fontStyle: 'italic'}}>{d.description}</p>
                    </div>
                ))}

                <h2>Vidéos</h2>
                <br/>
                {video.map(d => (
                    <div style={{padding: '0 10px'}} key={d.headline}>
                        <p>
                            <span style={{fontWeight: 'bold'}}>
                                {d.headline} </span>&nbsp;<em>({d.date})</em>
                        </p>
                        <p style={{padding: '0 30px', fontStyle: 'italic'}}>
                            {d.description}
                            <br/><br/>
                            {ReactHtmlParser(d.youtube)}
                        </p>
                    </div>
                ))}

            </Layout>
        )
    }
}

export default Media

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
