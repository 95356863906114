import React from 'react'
import { FaFilePdf } from 'react-icons/fa'

const data = [
    {
        date: 'Mai 2019',
        headline: 'Exposition de Valenciennes',
        description: `Vidéo réalisée l'exposition du Collectif d'Artistes du Hainaut Belge à Valenciennes, du 18 au 23 Mai 2019 avec pour invité d'honneur, André Gobert.`,
        youtube: '<iframe width="560" height="315" src="https://www.youtube.com/embed/vHGOdg-369Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        icon: <FaFilePdf style={{marginRight: '7px'}} />,
    }
];

export default data