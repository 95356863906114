import React from 'react'
import { FaFilePdf } from 'react-icons/fa'
import ExpoValenciennePdf from './../assets/docs/catalogue_valenciennes_mai_2019.pdf'
import ExpoDouaiPdf from './../assets/docs/catalogue_douai_septembre_2019.pdf'
import ExpoCharlevillePdf from './../assets/docs/catalogue_charleville_mezieres_octobre_2021.pdf'

const data = [
    {
        date: 'Octobre 2021',
        size: '51.8 Mo',
        headline: 'Catalogue - Exposition de Charleville-Mézières',
        description: `Catalogue reprenant les oeuvres et descriptions présentées lors de l'exposition du Collectif d'Artistes du Hainaut Belge à Charleville-Mézières, du 15 au 20 Octobre 2021.`,
        doc: ExpoCharlevillePdf,
        icon: <FaFilePdf style={{marginRight: '7px'}} />,
    },
    {
        date: 'Septembre 2019',
        size: '4.5 Mo',
        headline: 'Catalogue - Exposition de Douai',
        description: `Catalogue reprenant les oeuvres et descriptions présentées lors de l'exposition du Collectif d'Artistes du Hainaut Belge à Douai, du 6 au 8 Septembre 2019.`,
        doc: ExpoDouaiPdf,
        icon: <FaFilePdf style={{marginRight: '7px'}} />,
    },
    {
        date: 'Mai 2019',
        size: '7.8 Mo',
        headline: 'Catalogue - Exposition de Valenciennes',
        description: `Catalogue reprenant les oeuvres et descriptions présentées lors de l'exposition du Collectif d'Artistes du Hainaut Belge à Valenciennes, du 18 au 23 Mai 2019 avec pour invité d'honneur, André Gobert.`,
        doc: ExpoValenciennePdf,
        icon: <FaFilePdf style={{marginRight: '7px'}} />,
    },
];

export default data